.Load-More {
  background-color: transparent;
  border: 1px solid #3cca5e;
  border-radius: 4px;
  color: #3cca5e;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
}

.Load-More:focus,
.Load-More:hover {
  background-color: #3cca5e;
  color: #fff;
}
