.Footer {
  background: #f2f3f4 url('./stripes.png') repeat top left;
  border-top: 1px solid #e1e1e1;
  font-size: 14px;
  margin: 0;
  width: 100%;
}

.Footer::after {
  background: url('./ribbon.svg') repeat-x top left;
  content: '';
  display: block;
  height: 6px;
}

.Footer__wrapper {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 1024;
  padding: 20px;
}

.Footer__column {
  display: inline-block;
}

.Footer__column:not(:last-of-type) {
  margin: 0 0 30px;
}

.Footer__column-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.Footer__column-list:not(:last-child) {
  margin-bottom: 15px;
}

.Footer__column-list li {
  color: #5e6d79;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.Footer__column-list li:not(:last-child) {
  padding-bottom: 10px;
}

.Footer__column-list a {
  border: none;
  color: inherit;
}

.Footer__column-title {
  color: #001b36;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin: 0 0 10px;
  text-align: center;
}

.Footer__logo-canal-wrapper {
  display: none;
}

.Footer__logo-coop-wrapper {
  display: none;
}

.Footer__logo {
  height: 60px;
}

.Footer__qr {
  border-style: none;
  width: 48px;
}

@media (min-width: 768px) {
  .Footer__wrapper {
    align-items: flex-start;
    flex-direction: row;
    padding: 30px 15px;
  }

  .Footer__column:not(:last-of-type) {
    margin: 0 80px 0 0;
  }
}

@media (min-width: 1200px) {
  .Footer__logo-canal-wrapper,
  .Footer__logo-coop-wrapper {
    display: inline-block;
  }
}
