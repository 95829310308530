.Popover {
  border: 1px solid #ccc;
  box-shadow: -5px 5px 5px -5px #333;
  display: block;
  list-style: none;
  margin: 0;
  min-width: 220px;
  padding: 0;
}

.Popover__link {
  background-color: #f7f7f7;
  color: #333;
  cursor: pointer;
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding: 15px 25px;
  text-decoration: none;
}

.Popover__link:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.Popover__link:hover {
  background-color: #3cca5e;
  color: #fff;
}
