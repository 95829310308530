.Roster-Entry {
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
}

.Roster-Entry__left-column {
  align-items: center;
  background-color: #50555a;
  border-right: 1px solid #999;
  display: flex;
  justify-content: center;
  padding: 15px;
}

.Roster-Entry__right-column {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
}

.Roster-Entry__icon {
  height: 64px;
  width: 64px;
}

.Roster-Entry__activity {
  background-color: #212c37;
  border-bottom: 1px solid #999;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}

.Roster-Entry__details {
  align-items: center;
  background-color: #374858;
  color: #fff;
  display: flex;
  flex-grow: 1;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  justify-content: center;
  padding: 15px;
  text-align: center;
}

@media (min-width: 768px) {
  .Roster-Entry {
    border: 1px solid #ebebeb;
    flex-direction: column;
    min-height: 244px;
  }

  .Roster-Entry__left-column {
    background-color: #f7f7f7;
    border-right: none;
    display: block;
    padding: 25px 0;
    text-align: center;
  }

  .Roster-Entry__icon {
    height: 100px;
    width: 100px;
  }
}
