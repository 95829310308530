.No-Match {
  font-family: Lora, serif;
  font-size: 18px;
  line-height: 1.5;
  padding: 0 20px;
}

.No-Match__header {
  margin: 30px;
}

.No-Match__header-title {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.15;
  text-align: center;
}

.No-Match__header-image {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 200px;
}

@media (min-width: 768px) {
  .No-Match {
    padding: 20px;
  }
}
