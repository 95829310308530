.Header {
  background: linear-gradient(to top, #1e1e1e 0, #00253c 100%);
  border-bottom: 1px solid #ccc;
  border-top: 3px solid #3cca5e;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.44);
}

.Header__content {
  margin: 0 auto;
  max-width: 1150px;
  position: relative; /* This is to absolutely position the hamburger menu */
}

/* Separate logo vertically from navigation bar */
.Header__logo {
  margin: 10px 0 5px 20px;
}

/* Make sure this media query value matches the ones on Header/{Navigation, Logo}/styles.css */
@media (min-width: 1024px) {
  .Header {
    border-top-width: 4px;
  }

  .Header__content {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 25px;
  }

  .Header__logo {
    margin: 15px 0;
  }
}
