.Contact {
  display: flex;
  flex-wrap: wrap;
  max-width: 1096px;
  padding-top: 20px;
}

.Contact__title {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 700;
  margin: 15px 0 0;
}

.Contact__title::after {
  background-color: #666;
  content: '';
  display: block;
  height: 1px;
  margin-top: 10px;
  width: 100px;
}

.Contact__blurb {
  font-family: Lora, serif;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 15px;
}

.Contact__details,
.Contact__form {
  margin-top: 30px;
}

.Contact__right {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .Contact {
    flex-wrap: nowrap;
    padding-top: 35px;
  }

  .Contact__left,
  .Contact__right {
    flex-basis: 50%;
  }

  .Contact__left {
    margin-right: 50px;
  }

  .Contact__right {
    margin-left: 50px;
    margin-top: 0;
  }
}
