.Classified {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Classified__image {
  height: 100%;
  max-width: 150px;
  width: 100%;
}

.Classified__details {
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.Classified__details-title {
  font-size: 26px;
  font-weight: 900;
}

.Classified__details-description,
.Classified__details-contact {
  font-size: 20px;
  line-height: 1.15;
}

.Classified__details-title,
.Classified__details-description,
.Classified__details-contact {
  margin-bottom: 10px;
}

.Classified__details-contact {
  font-weight: 700;
}

.Classified__details-date {
  color: #999;
  font-size: 16px;
}
