.Dropdown {
  display: block;
  position: relative;
}

.Dropdown__button {
  background-color: transparent;
  text-align: left;
  width: 100%;
}

.Dropdown__popover {
  left: 0;
  margin-top: 50px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  z-index: 9999;
}
