/*
Taken from https://purecss.io v0.6.2
https://unpkg.com/purecss@0.6.2/build/pure.css
*/

input[type='text'],
input[type='password'],
input[type='email'],
input[type='url'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='datetime'],
input[type='datetime-local'],
input[type='week'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='color'],
select,
textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px #ddd;
  font-size: 16px;
  padding: 10px 12px;
  vertical-align: middle;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
select:focus,
textarea:focus {
  border-color: #129fea;
  outline: 0;
}

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: thin solid #129fea;
  outline: 1px auto #129fea;
}

input[type='text'][disabled],
input[type='password'][disabled],
input[type='email'][disabled],
input[type='url'][disabled],
input[type='date'][disabled],
input[type='month'][disabled],
input[type='time'][disabled],
input[type='datetime'][disabled],
input[type='datetime-local'][disabled],
input[type='week'][disabled],
input[type='number'][disabled],
input[type='search'][disabled],
input[type='tel'][disabled],
input[type='color'][disabled],
select[disabled],
textarea[disabled] {
  background-color: #eaeded;
  color: #cad2d3;
  cursor: not-allowed;
}

input[readonly],
select[readonly],
textarea[readonly] {
  background-color: #eee; /* menu hover bg color */
  border-color: #ccc;
  color: #777; /* menu text color */
}

input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
  border-color: #e9322d;
  color: #b94a48;
}

input[type='file']:focus:invalid:focus,
input[type='radio']:focus:invalid:focus,
input[type='checkbox']:focus:invalid:focus {
  outline-color: #e9322d;
}

select {
  /* Normalizes the height; padding is not sufficient. */
  background-color: white;
  border: 1px solid #ccc;
  height: 28px;
}

button {
  background-color: #3cca5e;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
}

button:hover {
  opacity: 0.9;
}
