.No-Results {
  text-align: center;
}

.No-Results__image {
  max-width: 250px;
  width: 100%;
}

.No-Results__caption {
  color: #374858;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 500;
  padding: 5px 20px;
}
