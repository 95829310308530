.Pharmacy {
  align-items: stretch;
  border: 1px solid #ebebeb;
  display: flex;
}

.Pharmacy__icon-container {
  display: none;
}

.Pharmacy__icon {
  width: 150px;
}

.Pharmacy__details {
  background-color: #f7f7f7;
  padding: 20px;
  text-align: left;
  width: 100%;
}

.Pharmacy__heading {
  color: #212c37;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: bold;
}

.Pharmacy__dates,
.Pharmacy__contact-details {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Pharmacy__contact-details {
  margin-top: 15px;
}

.Pharmacy__contact-details li {
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 14px;
  text-transform: uppercase;
}

.Pharmacy__contact-details--phone {
  background-color: #86c25f;
}

.Pharmacy__contact-details--address {
  background-color: #64aed3;
}

.Pharmacy__contact-details li:not(:last-child) {
  margin-right: 10px;
}

.Pharmacy__dates {
  color: #666;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin-top: 20px;
}

.Pharmacy__dates li:not(:last-child) {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .Pharmacy__icon-container {
    align-items: center;
    background-color: #374858;
    display: flex;
    justify-content: center;
    min-width: 200px;
    padding: 20px;
  }

  .Pharmacy__heading {
    font-size: 35px;
  }

  .Pharmacy__dates {
    font-size: 18px;
  }

  .Pharmacy__contact-details li {
    font-size: 17px;
    padding: 5px 20px;
  }
}
