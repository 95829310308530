.News-Feed {
  padding: 10px;
}

.News-Feed__item {
  cursor: pointer;
  display: block;
  margin: 0 0 15px;
  text-decoration: none;
}

.News-Feed__hero-container .Ad {
  margin-bottom: 15px;
}

.News-Feed__small-ads-container img {
  margin-bottom: 15px;
}

.News-Feed__load-more {
  text-align: center;
  width: 100%;
}

@media (min-width: 768px) {
  .News-Feed {
    margin: 0 auto;
    max-width: 1330px;
    padding: 30px 20px;
  }

  .News-Feed__hero-container {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .News-Feed__hero-container .News-Feed__item {
    height: 350px;
    margin-bottom: 0;
    width: 100%;
  }

  .News-Feed__hero-container article.Preview {
    width: 100%;
  }

  .News-Feed__hero-ad a {
    display: inline-block;
    height: 100%;
  }

  .News-Feed__hero-ad .Ad {
    border: 10px solid #00253c;
    height: 100%;
    margin-bottom: 0;
    width: 410px;
  }

  .News-Feed__small-ads-container {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px;
  }

  .News-Feed__small-ad {
    display: inline-block;
    width: 100%;
  }

  .News-Feed__small-ad img {
    width: 100%;
  }

  .News-Feed__small-ads-separator {
    display: inline-block;
    min-width: 30px;
  }

  .News-Feed__all-other-posts {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .News-Feed__item {
    margin: 0 30px 30px 0;
  }

  .News-Feed__all-other-posts .News-Feed__item:nth-child(4n) {
    margin-right: 0;
  }
}
