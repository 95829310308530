.Roster-List {
  display: flex;
  flex-direction: column;
}

.Roster-List__entry {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .Roster-List {
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Roster-List__entry {
    width: 200px;
  }

  .Roster-List__entry:not(:last-child) {
    margin-right: 15px;
  }
}
