/* @TODO: Preview--text and Preview--image should be two different components */
.Preview {
  display: block;
}

.Preview--text {
  padding: 10px;
}

.Preview--image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #ccc;
  min-height: 180px;
  position: relative;
}

.Preview__title--image {
  background-image: linear-gradient(transparent, #000);
  bottom: 0;
  color: white;
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 700;
  left: 0;
  padding: 12px;
  position: absolute;
  text-shadow: 2px 1px #333;
  transition: all 0.5s ease;
  width: 100%;
}

.Preview__title--text {
  color: #111;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.Preview__excerpt--text {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.3;
  margin: 10px 0 0;
}

.Preview__excerpt--image {
  display: none;
}

.Preview__category--text {
  background-color: #3cca5e;
  color: #fff;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 3px 6px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .Preview {
    display: inline-block;
    width: 300px;
  }

  .Preview--image {
    height: 100%;
    min-height: 300px;
  }

  .Preview--image:hover .Preview__excerpt--image {
    background-color: rgba(17, 17, 17, 0.92);
    bottom: 0;
    color: #fff;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    left: 0;
    line-height: 1.5;
    padding: 25px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .Preview--text {
    min-height: 180px;
    padding: 0;
  }

  .Preview__title--text {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
  }

  .Preview--text:hover .Preview__title--text {
    text-decoration: underline;
  }

  .Preview__excerpt--text {
    font-size: 16px;
  }

  .Preview--image:hover > .Preview__blurb {
    padding-bottom: 30px;
  }

  .Preview__title--image {
    padding: 20px;
  }

  .Preview__category--text {
    margin: 0;
  }
}
