.Navigation {
  display: block;
}

.Navigation__hamburger-menu {
  background-color: transparent;
  cursor: pointer;
  height: 23px;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 16px;
}

.Navigation__hamburger-menu-icon {
  height: 100%;
}

.Navigation__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/*
This is the container for the navigation items (dropdowns).
This navigation list starts off being hidden on mobile.
*/
.Navigation__list--collapsed {
  display: none;
}

/*
After clicking on the hamburguer menu on mobile, the navigation list becomes visible.
Should be a block element on mobile, but an inline-block element on desktop.
*/
.Navigation__list--expanded {
  display: block;
}

.Navigation__list-item {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  padding: 10px;
}

.Navigation__link {
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 12px 10px;
  text-decoration: none;
  text-transform: uppercase;
}

.Navigation__link:focus,
.Navigation__link:hover {
  background-color: #3cca5e;
}

/* Make sure this media query value matches the one on Header/styles.css */
@media (min-width: 1024px) {
  .Navigation {
    display: inline-block;
  }

  .Navigation__hamburger-menu {
    display: none;
  }

  .Navigation__list--expanded,
  .Navigation__list--collapsed {
    display: inline-block;
  }

  .Navigation__list-item {
    border-top: none;
    display: inline-block;
    padding: 0;
  }

  .Navigation__list-item:not(:last-child) {
    margin-right: 15px;
  }
}
