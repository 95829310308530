.Roster__list-container {
  margin-top: 20px;
  padding: 0 20px;
  text-align: center;
}

.Roster-List,
.Roster__phones {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .Roster__list-container {
    margin: 45px auto 0;
    padding: 0;
  }

  .Roster__list {
    display: inline-block;
  }
}
