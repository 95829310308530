.Logo {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  transition-delay: 0;
  transition-duration: 167ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.Logo:active {
  transform: scale(0.97);
}

.Logo__symbol {
  height: 36px; /* Must match .Logo__logotype's height */
}

.Logo__logotype {
  height: 36px; /* Must match .Logo__symbol's height */
  margin-left: 10px;
}

/* Make sure this media query value matches the one on Header/styles.css */
@media (min-width: 1024px) {
  .Logo__symbol {
    height: 60px;
  }

  .Logo__logotype {
    height: 50px;
    margin-left: 15px;
  }
}
