.Carousel__slide {
  background-color: #1b1b1b;
  height: 200px;
  text-align: center;
}

.Carousel__image--slide,
.Carousel__video--slide {
  height: 100%;
  max-width: 100%;
}

.Carousel__image--standalone,
.Carousel__video--standalone {
  height: 100%;
  width: 100%;
}

.Carousel__video--standalone,
.Carousel__video--slide {
  min-height: 200px;
}

@media (min-width: 768px) {
  .Carousel__slide {
    height: 500px;
  }

  .Carousel__video--standalone,
  .Carousel__video--slide {
    min-height: 500px;
  }
}
