@import url(https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i|Montserrat:500,700|Roboto:400,700,900);
.Logo {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  transition-delay: 0;
  transition-duration: 167ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.Logo:active {
  transform: scale(0.97);
}

.Logo__symbol {
  height: 36px; /* Must match .Logo__logotype's height */
}

.Logo__logotype {
  height: 36px; /* Must match .Logo__symbol's height */
  margin-left: 10px;
}

/* Make sure this media query value matches the one on Header/styles.css */
@media (min-width: 1024px) {
  .Logo__symbol {
    height: 60px;
  }

  .Logo__logotype {
    height: 50px;
    margin-left: 15px;
  }
}

.Popover {
  border: 1px solid #ccc;
  box-shadow: -5px 5px 5px -5px #333;
  display: block;
  list-style: none;
  margin: 0;
  min-width: 220px;
  padding: 0;
}

.Popover__link {
  background-color: #f7f7f7;
  color: #333;
  cursor: pointer;
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding: 15px 25px;
  text-decoration: none;
}

.Popover__link:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.Popover__link:hover {
  background-color: #3cca5e;
  color: #fff;
}

.Dropdown {
  display: block;
  position: relative;
}

.Dropdown__button {
  background-color: transparent;
  text-align: left;
  width: 100%;
}

.Dropdown__popover {
  left: 0;
  margin-top: 50px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  z-index: 9999;
}

.Navigation {
  display: block;
}

.Navigation__hamburger-menu {
  background-color: transparent;
  cursor: pointer;
  height: 23px;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 16px;
}

.Navigation__hamburger-menu-icon {
  height: 100%;
}

.Navigation__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/*
This is the container for the navigation items (dropdowns).
This navigation list starts off being hidden on mobile.
*/
.Navigation__list--collapsed {
  display: none;
}

/*
After clicking on the hamburguer menu on mobile, the navigation list becomes visible.
Should be a block element on mobile, but an inline-block element on desktop.
*/
.Navigation__list--expanded {
  display: block;
}

.Navigation__list-item {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  padding: 10px;
}

.Navigation__link {
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 12px 10px;
  text-decoration: none;
  text-transform: uppercase;
}

.Navigation__link:focus,
.Navigation__link:hover {
  background-color: #3cca5e;
}

/* Make sure this media query value matches the one on Header/styles.css */
@media (min-width: 1024px) {
  .Navigation {
    display: inline-block;
  }

  .Navigation__hamburger-menu {
    display: none;
  }

  .Navigation__list--expanded,
  .Navigation__list--collapsed {
    display: inline-block;
  }

  .Navigation__list-item {
    border-top: none;
    display: inline-block;
    padding: 0;
  }

  .Navigation__list-item:not(:last-child) {
    margin-right: 15px;
  }
}

.Header {
  background: linear-gradient(to top, #1e1e1e 0, #00253c 100%);
  border-bottom: 1px solid #ccc;
  border-top: 3px solid #3cca5e;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.44);
}

.Header__content {
  margin: 0 auto;
  max-width: 1150px;
  position: relative; /* This is to absolutely position the hamburger menu */
}

/* Separate logo vertically from navigation bar */
.Header__logo {
  margin: 10px 0 5px 20px;
}

/* Make sure this media query value matches the ones on Header/{Navigation, Logo}/styles.css */
@media (min-width: 1024px) {
  .Header {
    border-top-width: 4px;
  }

  .Header__content {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 25px;
  }

  .Header__logo {
    margin: 15px 0;
  }
}

.Footer {
  background: #f2f3f4 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAKklEQVQIW2NkYGCQZGBgeM6ABhihfAxJmARIHkUSWQJFEl0CLolNAiwJAKi/BSBojR18AAAAAElFTkSuQmCC) repeat top left;
  border-top: 1px solid #e1e1e1;
  font-size: 14px;
  margin: 0;
  width: 100%;
}

.Footer::after {
  background: url(/static/media/ribbon.b52f5fc8.svg) repeat-x top left;
  content: '';
  display: block;
  height: 6px;
}

.Footer__wrapper {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 1024;
  padding: 20px;
}

.Footer__column {
  display: inline-block;
}

.Footer__column:not(:last-of-type) {
  margin: 0 0 30px;
}

.Footer__column-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.Footer__column-list:not(:last-child) {
  margin-bottom: 15px;
}

.Footer__column-list li {
  color: #5e6d79;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.Footer__column-list li:not(:last-child) {
  padding-bottom: 10px;
}

.Footer__column-list a {
  border: none;
  color: inherit;
}

.Footer__column-title {
  color: #001b36;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin: 0 0 10px;
  text-align: center;
}

.Footer__logo-canal-wrapper {
  display: none;
}

.Footer__logo-coop-wrapper {
  display: none;
}

.Footer__logo {
  height: 60px;
}

.Footer__qr {
  border-style: none;
  width: 48px;
}

@media (min-width: 768px) {
  .Footer__wrapper {
    align-items: flex-start;
    flex-direction: row;
    padding: 30px 15px;
  }

  .Footer__column:not(:last-of-type) {
    margin: 0 80px 0 0;
  }
}

@media (min-width: 1200px) {
  .Footer__logo-canal-wrapper,
  .Footer__logo-coop-wrapper {
    display: inline-block;
  }
}

.Alert {
  align-items: center;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 1.5;
  margin-top: 10px;
  padding: 20px;
}

.Alert--success {
  background-color: #3cca5e; /* Green */
}

.Alert--error {
  background-color: #f2565a; /* Red */
}

.Alert--info {
  background-color: #00a1f1; /* Blue */
}

.Alert__close-control {
  align-items: center;
  background-color: transparent;
  display: flex;
  margin-left: 20px;
  padding: 0;
}

.Alert__close-icon {
  cursor: pointer;
  height: 16px;
  width: 20px;
}

/* Full screen overlay */
.Spinner {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

.Spinner__image-wrapper {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000; /* overlay's z-index + 1 */
}

.Spinner__image {
  animation: spin 1s linear infinite;
  width: 60px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.App__alerts {
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  left: 0;
  max-width: 500px;
  padding: 10px;
  position: fixed;
  width: 100%;
}

@media (min-width: 768px) {
  .App__alerts {
    padding: 20px;
  }
}

.Ad {
  height: 100%;
  width: 100%;
}

/* @TODO: Preview--text and Preview--image should be two different components */
.Preview {
  display: block;
}

.Preview--text {
  padding: 10px;
}

.Preview--image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #ccc;
  min-height: 180px;
  position: relative;
}

.Preview__title--image {
  background-image: linear-gradient(transparent, #000);
  bottom: 0;
  color: white;
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 700;
  left: 0;
  padding: 12px;
  position: absolute;
  text-shadow: 2px 1px #333;
  transition: all 0.5s ease;
  width: 100%;
}

.Preview__title--text {
  color: #111;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.Preview__excerpt--text {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.3;
  margin: 10px 0 0;
}

.Preview__excerpt--image {
  display: none;
}

.Preview__category--text {
  background-color: #3cca5e;
  color: #fff;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 3px 6px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .Preview {
    display: inline-block;
    width: 300px;
  }

  .Preview--image {
    height: 100%;
    min-height: 300px;
  }

  .Preview--image:hover .Preview__excerpt--image {
    background-color: rgba(17, 17, 17, 0.92);
    bottom: 0;
    color: #fff;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    left: 0;
    line-height: 1.5;
    padding: 25px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .Preview--text {
    min-height: 180px;
    padding: 0;
  }

  .Preview__title--text {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
  }

  .Preview--text:hover .Preview__title--text {
    text-decoration: underline;
  }

  .Preview__excerpt--text {
    font-size: 16px;
  }

  .Preview--image:hover > .Preview__blurb {
    padding-bottom: 30px;
  }

  .Preview__title--image {
    padding: 20px;
  }

  .Preview__category--text {
    margin: 0;
  }
}

.Load-More {
  background-color: transparent;
  border: 1px solid #3cca5e;
  border-radius: 4px;
  color: #3cca5e;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
}

.Load-More:focus,
.Load-More:hover {
  background-color: #3cca5e;
  color: #fff;
}

.News-Feed {
  padding: 10px;
}

.News-Feed__item {
  cursor: pointer;
  display: block;
  margin: 0 0 15px;
  text-decoration: none;
}

.News-Feed__hero-container .Ad {
  margin-bottom: 15px;
}

.News-Feed__small-ads-container img {
  margin-bottom: 15px;
}

.News-Feed__load-more {
  text-align: center;
  width: 100%;
}

@media (min-width: 768px) {
  .News-Feed {
    margin: 0 auto;
    max-width: 1330px;
    padding: 30px 20px;
  }

  .News-Feed__hero-container {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .News-Feed__hero-container .News-Feed__item {
    height: 350px;
    margin-bottom: 0;
    width: 100%;
  }

  .News-Feed__hero-container article.Preview {
    width: 100%;
  }

  .News-Feed__hero-ad a {
    display: inline-block;
    height: 100%;
  }

  .News-Feed__hero-ad .Ad {
    border: 10px solid #00253c;
    height: 100%;
    margin-bottom: 0;
    width: 410px;
  }

  .News-Feed__small-ads-container {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px;
  }

  .News-Feed__small-ad {
    display: inline-block;
    width: 100%;
  }

  .News-Feed__small-ad img {
    width: 100%;
  }

  .News-Feed__small-ads-separator {
    display: inline-block;
    min-width: 30px;
  }

  .News-Feed__all-other-posts {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .News-Feed__item {
    margin: 0 30px 30px 0;
  }

  .News-Feed__all-other-posts .News-Feed__item:nth-child(4n) {
    margin-right: 0;
  }
}

.Carousel__slide {
  background-color: #1b1b1b;
  height: 200px;
  text-align: center;
}

.Carousel__image--slide,
.Carousel__video--slide {
  height: 100%;
  max-width: 100%;
}

.Carousel__image--standalone,
.Carousel__video--standalone {
  height: 100%;
  width: 100%;
}

.Carousel__video--standalone,
.Carousel__video--slide {
  min-height: 200px;
}

@media (min-width: 768px) {
  .Carousel__slide {
    height: 500px;
  }

  .Carousel__video--standalone,
  .Carousel__video--slide {
    min-height: 500px;
  }
}

.Share,
.Share__link {
  display: inline-block;
}

.Share__link {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.Share__link:not(:last-child) {
  margin-right: 15px;
}

.Share__icon {
  height: 35px;
  width: 35px;
}

.Container {
  margin: 0 auto;
  max-width: 768px;
  padding: 20px 20px 35px;
}

.Post {
  padding-top: 30px;
}

.Post__aside {
  display: flex;
  flex-direction: column;
}

.Post__details__label {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 6px 10px;
  text-transform: uppercase;
}

.Post__details__label:not(:last-of-type) {
  margin-right: 10px;
}

.Post__ruler {
  background-color: #e7e7e7;
  border: none;
  height: 4px;
  margin: 20px 0;
}

.Post__title {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 900;
  margin: 0;
}

.Post__excerpt {
  color: #333;
  font-family: Lora, serif;
  font-size: 18px;
  font-style: italic;
  line-height: 1.6;
  margin: 20px 0;
}

.Post__body {
  color: #333;
  font-family: Lora, serif;
  font-size: 18px;
  line-height: 1.6;
}

.Post__body a {
  border-bottom: 1px solid #e1e1e1;
  color: #056cc1;
  padding-bottom: 4px;
  text-decoration: none;
  transition: border-bottom 0.5s ease;
}

.Post__body a:hover {
  border-bottom: 1px solid #056cc1;
}

@media (min-width: 768px) {
  .Post {
    padding-top: 45px;
  }

  .Post__aside {
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .Post__details__label {
    margin-bottom: 0;
  }

  .Post__title {
    font-size: 36px;
  }

  .Post__excerpt,
  .Post__body {
    font-size: 21px;
    text-align: justify;
  }
}

.Hero {
  align-items: center;
  background: url(/static/media/background.b0e1c089.png) no-repeat center center fixed;
  background-size: cover;
  border-bottom: 1px solid #ccc;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  padding: 0 20px;
}

.Hero__title {
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}

.Hero__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin: 5px 0 0;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .Hero {
    border-bottom: none;
    box-shadow: 0 8px 6px -6px #858585;
    height: 250px;
  }

  .Hero__title {
    font-size: 78px;
  }

  .Hero__subtitle {
    font-size: 20px;
  }
}

.Page__title {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-weight: 900;
  margin: 0;
}

.Page__body {
  color: #333;
  font-family: Lora, serif;
  font-size: 18px;
  line-height: 1.6;
}

.Page__body h1,
.Page__body h2,
.Page__body h3,
.Page__body h4,
.Page__body h5,
.Page__body h6 {
  font-family: Roboto, sans-serif;
  margin: 0;
}

@media (min-width: 768px) {
  .Page__body {
    font-size: 21px;
    text-align: justify;
  }
}

.About__content {
  margin-top: 20px;
}

.Live__content {
  margin-top: 20px;
}

.video-container {
position: relative;
padding-bottom: 56.25%;
padding-top: 30px; height: 0; overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

.Roster-Entry {
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
}

.Roster-Entry__left-column {
  align-items: center;
  background-color: #50555a;
  border-right: 1px solid #999;
  display: flex;
  justify-content: center;
  padding: 15px;
}

.Roster-Entry__right-column {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
}

.Roster-Entry__icon {
  height: 64px;
  width: 64px;
}

.Roster-Entry__activity {
  background-color: #212c37;
  border-bottom: 1px solid #999;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}

.Roster-Entry__details {
  align-items: center;
  background-color: #374858;
  color: #fff;
  display: flex;
  flex-grow: 1;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  justify-content: center;
  padding: 15px;
  text-align: center;
}

@media (min-width: 768px) {
  .Roster-Entry {
    border: 1px solid #ebebeb;
    flex-direction: column;
    min-height: 244px;
  }

  .Roster-Entry__left-column {
    background-color: #f7f7f7;
    border-right: none;
    display: block;
    padding: 25px 0;
    text-align: center;
  }

  .Roster-Entry__icon {
    height: 100px;
    width: 100px;
  }
}

.Roster-List {
  display: flex;
  flex-direction: column;
}

.Roster-List__entry {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .Roster-List {
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Roster-List__entry {
    width: 200px;
  }

  .Roster-List__entry:not(:last-child) {
    margin-right: 15px;
  }
}

.Pharmacy {
  align-items: stretch;
  border: 1px solid #ebebeb;
  display: flex;
}

.Pharmacy__icon-container {
  display: none;
}

.Pharmacy__icon {
  width: 150px;
}

.Pharmacy__details {
  background-color: #f7f7f7;
  padding: 20px;
  text-align: left;
  width: 100%;
}

.Pharmacy__heading {
  color: #212c37;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: bold;
}

.Pharmacy__dates,
.Pharmacy__contact-details {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Pharmacy__contact-details {
  margin-top: 15px;
}

.Pharmacy__contact-details li {
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 14px;
  text-transform: uppercase;
}

.Pharmacy__contact-details--phone {
  background-color: #86c25f;
}

.Pharmacy__contact-details--address {
  background-color: #64aed3;
}

.Pharmacy__contact-details li:not(:last-child) {
  margin-right: 10px;
}

.Pharmacy__dates {
  color: #666;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin-top: 20px;
}

.Pharmacy__dates li:not(:last-child) {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .Pharmacy__icon-container {
    align-items: center;
    background-color: #374858;
    display: flex;
    justify-content: center;
    min-width: 200px;
    padding: 20px;
  }

  .Pharmacy__heading {
    font-size: 35px;
  }

  .Pharmacy__dates {
    font-size: 18px;
  }

  .Pharmacy__contact-details li {
    font-size: 17px;
    padding: 5px 20px;
  }
}

.Roster__list-container {
  margin-top: 20px;
  padding: 0 20px;
  text-align: center;
}

.Roster-List,
.Roster__phones {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .Roster__list-container {
    margin: 45px auto 0;
    padding: 0;
  }

  .Roster__list {
    display: inline-block;
  }
}

.Details {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Details__item {
  background-color: rgba(0, 0, 0, 0.035);
  border: 1px solid #ddd;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  padding: 10px;
  position: relative;
}

.Details__item:not(:last-child) {
  border-bottom: none;
}

.Details__item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.Details__item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.Details__item a {
  color: inherit;
  text-decoration: none;
}

.Details__item a:hover {
  color: #3cca5e;
}

.Details__item-icon {
  height: 100%;
  position: absolute;
  right: 20px;
  top: 0;
  width: 20px;
}

.Details__item-label {
  color: #444345;
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-size: 12px;
  font-weight: 100;
  font-weight: 500;
  letter-spacing: -1px;
  letter-spacing: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.Form__field {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding: 0 0 20px;
}

.Form__field input,
.Form__field textarea {
  display: block;
  margin-top: 5px;
  width: 100%;
}

.Form__field textarea {
  height: 100px;
}

.Form__errors {
  color: #f2565a;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin-bottom: 25px;
}

.Form__submit {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.Contact {
  display: flex;
  flex-wrap: wrap;
  max-width: 1096px;
  padding-top: 20px;
}

.Contact__title {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 700;
  margin: 15px 0 0;
}

.Contact__title::after {
  background-color: #666;
  content: '';
  display: block;
  height: 1px;
  margin-top: 10px;
  width: 100px;
}

.Contact__blurb {
  font-family: Lora, serif;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 15px;
}

.Contact__details,
.Contact__form {
  margin-top: 30px;
}

.Contact__right {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .Contact {
    flex-wrap: nowrap;
    padding-top: 35px;
  }

  .Contact__left,
  .Contact__right {
    flex-basis: 50%;
  }

  .Contact__left {
    margin-right: 50px;
  }

  .Contact__right {
    margin-left: 50px;
    margin-top: 0;
  }
}

.Weather-Details__spinner {
  animation: sk-rotateplane 1.2s infinite ease-in-out;
  background-color: #3cca5e;
  border: 1px solid #0ba10b;
  height: 40px;
  margin: 100px auto;
  width: 40px;
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.Weather-Details__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.Weather-Details__overview {
  order: 1;
  text-align: center;
}

.Weather-Details__icon {
  width: 150px;
}

.Weather-Details__icon-wrapper {
  margin-bottom: 20px;
  order: 0;
  text-align: center;
}

.Weather-Details__boxes {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Weather-Details__box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.Weather-Details__box:not(:last-child) {
  margin-bottom: 10px;
}

.Weather-Details__box-label {
  background-color: #1b1b1b;
  color: #fff;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  padding: 5px 10px;
}

.Weather-Details__box-value {
  color: #fff;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  padding: 5px 10px;
}

.Weather-Details__box-value--high {
  background-color: #f2565a;
}

.Weather-Details__box-value--mid {
  background-color: #3cca5e;
}

.Weather-Details__box-value--low {
  background-color: #00a1f1;
}

.Weather-Details__highlight {
  margin-bottom: 20px;
}

.Weather-Details__highlight-value {
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
}

.Weather-Details__highlight-label {
  color: #999;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

/* Details subtitle */
.Weather-Details__subtitle {
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 700;
  margin: 40px 0 10px;
}

/* Details list */
.Weather-Details__list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.Weather-Details__list-item {
  border-bottom: 1px solid #d2d2dd;
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  padding: 18px 0;
}

.Weather-Details__list-item:first-child {
  border-top: 1px solid #d2d2dd;
}

/* Meteogram */
.Weather-Details__meteogram-link {
  display: block;
}

.Weather-Details__meteogram {
  width: 100%;
}

/* Local Station Details */
.Weather-Details__image {
  width: 100%;
}

/* Forecast */
.Weather-Details__forecast-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.Weather-Details__forecast-item:not(:last-child) {
  margin-bottom: 16px;
}

/* Disclaimer and last update */
.Weather-Details__disclaimer {
  color: #111;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  margin: 40px 0 0;
}

@media (min-width: 768px) {
  .Weather-Details__header {
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
  }

  .Weather-Details__overview {
    order: 0;
    text-align: left;
  }

  .Weather-Details__highlight-value {
    font-size: 140px;
  }

  .Weather-Details__highlight-label {
    font-size: 25px;
    letter-spacing: -2px;
    margin-top: -5px;
  }

  .Weather-Details__boxes {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 10px;
  }

  .Weather-Details__box {
    margin-bottom: 0;
  }

  .Weather-Details__box:not(:last-child) {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .Weather-Details__icon-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 0;
  }

  .Weather-Details__icon {
    width: 200px;
  }
}

.Classified {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Classified__image {
  height: 100%;
  max-width: 150px;
  width: 100%;
}

.Classified__details {
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.Classified__details-title {
  font-size: 26px;
  font-weight: 900;
}

.Classified__details-description,
.Classified__details-contact {
  font-size: 20px;
  line-height: 1.15;
}

.Classified__details-title,
.Classified__details-description,
.Classified__details-contact {
  margin-bottom: 10px;
}

.Classified__details-contact {
  font-weight: 700;
}

.Classified__details-date {
  color: #999;
  font-size: 16px;
}

.No-Results {
  text-align: center;
}

.No-Results__image {
  max-width: 250px;
  width: 100%;
}

.No-Results__caption {
  color: #374858;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 500;
  padding: 5px 20px;
}

.Classifieds__content {
  margin-top: 20px;
}

.Classifieds__item:not(:last-child) {
  margin-bottom: 50px;
}

.Obituary {
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.Obituary__name {
  font-size: 26px;
  font-weight: 900;
}

.Obituary__details,
.Obituary__age,
.Obituary__service {
  font-size: 20px;
  line-height: 1.15;
}

.Obituary__name,
.Obituary__age {
  margin-bottom: 10px;
}

.Obituary__details {
  margin-top: 10px;
  color: #777;
}

.Obituary__heading {
  font-weight: 700;
}

.Obituaries__content {
  margin-top: 20px;
}

.Obituaries__entry:not(:last-child) {
  margin-bottom: 50px;
}

.No-Match {
  font-family: Lora, serif;
  font-size: 18px;
  line-height: 1.5;
  padding: 0 20px;
}

.No-Match__header {
  margin: 30px;
}

.No-Match__header-title {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.15;
  text-align: center;
}

.No-Match__header-image {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 200px;
}

@media (min-width: 768px) {
  .No-Match {
    padding: 20px;
  }
}

/*
Taken from https://purecss.io v0.6.2
https://unpkg.com/purecss@0.6.2/build/pure.css
*/

input[type='text'],
input[type='password'],
input[type='email'],
input[type='url'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='datetime'],
input[type='datetime-local'],
input[type='week'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='color'],
select,
textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px #ddd;
  font-size: 16px;
  padding: 10px 12px;
  vertical-align: middle;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
select:focus,
textarea:focus {
  border-color: #129fea;
  outline: 0;
}

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: thin solid #129fea;
  outline: 1px auto #129fea;
}

input[type='text'][disabled],
input[type='password'][disabled],
input[type='email'][disabled],
input[type='url'][disabled],
input[type='date'][disabled],
input[type='month'][disabled],
input[type='time'][disabled],
input[type='datetime'][disabled],
input[type='datetime-local'][disabled],
input[type='week'][disabled],
input[type='number'][disabled],
input[type='search'][disabled],
input[type='tel'][disabled],
input[type='color'][disabled],
select[disabled],
textarea[disabled] {
  background-color: #eaeded;
  color: #cad2d3;
  cursor: not-allowed;
}

input[readonly],
select[readonly],
textarea[readonly] {
  background-color: #eee; /* menu hover bg color */
  border-color: #ccc;
  color: #777; /* menu text color */
}

input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
  border-color: #e9322d;
  color: #b94a48;
}

input[type='file']:focus:invalid:focus,
input[type='radio']:focus:invalid:focus,
input[type='checkbox']:focus:invalid:focus {
  outline-color: #e9322d;
}

select {
  /* Normalizes the height; padding is not sufficient. */
  background-color: white;
  border: 1px solid #ccc;
  height: 28px;
}

button {
  background-color: #3cca5e;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
}

button:hover {
  opacity: 0.9;
}

/*
 * Normalize.css makes browsers render all elements
 * more consistently and in line with modern standards.
 * It precisely targets only the styles that need normalizing.
 */

/*
 * Typography
 */

/*
 * Apply a natural box layout model to all elements,
 * but allowing components to change.
 */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

/* Basic form styles */

