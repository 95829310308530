.Details {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Details__item {
  background-color: rgba(0, 0, 0, 0.035);
  border: 1px solid #ddd;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  padding: 10px;
  position: relative;
}

.Details__item:not(:last-child) {
  border-bottom: none;
}

.Details__item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.Details__item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.Details__item a {
  color: inherit;
  text-decoration: none;
}

.Details__item a:hover {
  color: #3cca5e;
}

.Details__item-icon {
  height: 100%;
  position: absolute;
  right: 20px;
  top: 0;
  width: 20px;
}

.Details__item-label {
  color: #444345;
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-size: 12px;
  font-weight: 100;
  font-weight: 500;
  letter-spacing: -1px;
  letter-spacing: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
}
