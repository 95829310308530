.Form__field {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding: 0 0 20px;
}

.Form__field input,
.Form__field textarea {
  display: block;
  margin-top: 5px;
  width: 100%;
}

.Form__field textarea {
  height: 100px;
}

.Form__errors {
  color: #f2565a;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin-bottom: 25px;
}

.Form__submit {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
}
