.Obituary {
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.Obituary__name {
  font-size: 26px;
  font-weight: 900;
}

.Obituary__details,
.Obituary__age,
.Obituary__service {
  font-size: 20px;
  line-height: 1.15;
}

.Obituary__name,
.Obituary__age {
  margin-bottom: 10px;
}

.Obituary__details {
  margin-top: 10px;
  color: #777;
}

.Obituary__heading {
  font-weight: 700;
}
