.Post {
  padding-top: 30px;
}

.Post__aside {
  display: flex;
  flex-direction: column;
}

.Post__details__label {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 6px 10px;
  text-transform: uppercase;
}

.Post__details__label:not(:last-of-type) {
  margin-right: 10px;
}

.Post__ruler {
  background-color: #e7e7e7;
  border: none;
  height: 4px;
  margin: 20px 0;
}

.Post__title {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 900;
  margin: 0;
}

.Post__excerpt {
  color: #333;
  font-family: Lora, serif;
  font-size: 18px;
  font-style: italic;
  line-height: 1.6;
  margin: 20px 0;
}

.Post__body {
  color: #333;
  font-family: Lora, serif;
  font-size: 18px;
  line-height: 1.6;
}

.Post__body a {
  border-bottom: 1px solid #e1e1e1;
  color: #056cc1;
  padding-bottom: 4px;
  text-decoration: none;
  transition: border-bottom 0.5s ease;
}

.Post__body a:hover {
  border-bottom: 1px solid #056cc1;
}

@media (min-width: 768px) {
  .Post {
    padding-top: 45px;
  }

  .Post__aside {
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .Post__details__label {
    margin-bottom: 0;
  }

  .Post__title {
    font-size: 36px;
  }

  .Post__excerpt,
  .Post__body {
    font-size: 21px;
    text-align: justify;
  }
}
