/*
 * Normalize.css makes browsers render all elements
 * more consistently and in line with modern standards.
 * It precisely targets only the styles that need normalizing.
 */
@import url('../../node_modules/normalize.css/normalize.css');

/*
 * Typography
 */
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i|Montserrat:500,700|Roboto:400,700,900');

/*
 * Apply a natural box layout model to all elements,
 * but allowing components to change.
 */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

/* Basic form styles */
@import './forms.css';
