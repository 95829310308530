.Alert {
  align-items: center;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 1.5;
  margin-top: 10px;
  padding: 20px;
}

.Alert--success {
  background-color: #3cca5e; /* Green */
}

.Alert--error {
  background-color: #f2565a; /* Red */
}

.Alert--info {
  background-color: #00a1f1; /* Blue */
}

.Alert__close-control {
  align-items: center;
  background-color: transparent;
  display: flex;
  margin-left: 20px;
  padding: 0;
}

.Alert__close-icon {
  cursor: pointer;
  height: 16px;
  width: 20px;
}
