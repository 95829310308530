.App__alerts {
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  left: 0;
  max-width: 500px;
  padding: 10px;
  position: fixed;
  width: 100%;
}

@media (min-width: 768px) {
  .App__alerts {
    padding: 20px;
  }
}
