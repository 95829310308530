.Hero {
  align-items: center;
  background: url('./background.png') no-repeat center center fixed;
  background-size: cover;
  border-bottom: 1px solid #ccc;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  padding: 0 20px;
}

.Hero__title {
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}

.Hero__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin: 5px 0 0;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .Hero {
    border-bottom: none;
    box-shadow: 0 8px 6px -6px #858585;
    height: 250px;
  }

  .Hero__title {
    font-size: 78px;
  }

  .Hero__subtitle {
    font-size: 20px;
  }
}
