.Share,
.Share__link {
  display: inline-block;
}

.Share__link {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.Share__link:not(:last-child) {
  margin-right: 15px;
}

.Share__icon {
  height: 35px;
  width: 35px;
}
