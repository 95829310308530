.Weather-Details__spinner {
  animation: sk-rotateplane 1.2s infinite ease-in-out;
  background-color: #3cca5e;
  border: 1px solid #0ba10b;
  height: 40px;
  margin: 100px auto;
  width: 40px;
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.Weather-Details__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.Weather-Details__overview {
  order: 1;
  text-align: center;
}

.Weather-Details__icon {
  width: 150px;
}

.Weather-Details__icon-wrapper {
  margin-bottom: 20px;
  order: 0;
  text-align: center;
}

.Weather-Details__boxes {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Weather-Details__box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.Weather-Details__box:not(:last-child) {
  margin-bottom: 10px;
}

.Weather-Details__box-label {
  background-color: #1b1b1b;
  color: #fff;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  padding: 5px 10px;
}

.Weather-Details__box-value {
  color: #fff;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  padding: 5px 10px;
}

.Weather-Details__box-value--high {
  background-color: #f2565a;
}

.Weather-Details__box-value--mid {
  background-color: #3cca5e;
}

.Weather-Details__box-value--low {
  background-color: #00a1f1;
}

.Weather-Details__highlight {
  margin-bottom: 20px;
}

.Weather-Details__highlight-value {
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
}

.Weather-Details__highlight-label {
  color: #999;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

/* Details subtitle */
.Weather-Details__subtitle {
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 700;
  margin: 40px 0 10px;
}

/* Details list */
.Weather-Details__list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.Weather-Details__list-item {
  border-bottom: 1px solid #d2d2dd;
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  padding: 18px 0;
}

.Weather-Details__list-item:first-child {
  border-top: 1px solid #d2d2dd;
}

/* Meteogram */
.Weather-Details__meteogram-link {
  display: block;
}

.Weather-Details__meteogram {
  width: 100%;
}

/* Local Station Details */
.Weather-Details__image {
  width: 100%;
}

/* Forecast */
.Weather-Details__forecast-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.Weather-Details__forecast-item:not(:last-child) {
  margin-bottom: 16px;
}

/* Disclaimer and last update */
.Weather-Details__disclaimer {
  color: #111;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  margin: 40px 0 0;
}

@media (min-width: 768px) {
  .Weather-Details__header {
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
  }

  .Weather-Details__overview {
    order: 0;
    text-align: left;
  }

  .Weather-Details__highlight-value {
    font-size: 140px;
  }

  .Weather-Details__highlight-label {
    font-size: 25px;
    letter-spacing: -2px;
    margin-top: -5px;
  }

  .Weather-Details__boxes {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 10px;
  }

  .Weather-Details__box {
    margin-bottom: 0;
  }

  .Weather-Details__box:not(:last-child) {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .Weather-Details__icon-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 0;
  }

  .Weather-Details__icon {
    width: 200px;
  }
}
