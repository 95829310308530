/* Full screen overlay */
.Spinner {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

.Spinner__image-wrapper {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000; /* overlay's z-index + 1 */
}

.Spinner__image {
  animation: spin 1s linear infinite;
  width: 60px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
