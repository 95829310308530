.Page__title {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-weight: 900;
  margin: 0;
}

.Page__body {
  color: #333;
  font-family: Lora, serif;
  font-size: 18px;
  line-height: 1.6;
}

.Page__body h1,
.Page__body h2,
.Page__body h3,
.Page__body h4,
.Page__body h5,
.Page__body h6 {
  font-family: Roboto, sans-serif;
  margin: 0;
}

@media (min-width: 768px) {
  .Page__body {
    font-size: 21px;
    text-align: justify;
  }
}
